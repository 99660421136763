<template>
  <b-row>
    <b-col cols="12">
      <search-view :click-search="getInstallationTypes"/>
    </b-col>


    <b-col cols="12">
      <b-card title="">
        <b-overlay
            variant="white"
            :show="showLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
        >
          <div class="ml-1 mb-2">
            <b-row>
              <b-col cols="12" md="9"
                     class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Mostrar</label>
                <v-select
                    v-model="pageLength"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="['3','5','10','20']"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                    @input="changeLength"
                />
                <b-button variant="primary" @click="goToCreate()">
                  Añadir nuevo tipo de instalación
                </b-button>
              </b-col>
            </b-row>
          </div>

          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="installation_types"
              responsive
              striped
              sticky-header
              :fields="columns"
              primary-key="id"
              show-empty
              empty-text="No hay registros"
          >
            <template #cell(name)="data" >
              <router-link :to="{ name: 'users_edit', params: { id: data.item.id} }">
                {{ data.item.name }}
              </router-link>
            </template>

            <template #cell(actions)="data" class="col-sm-2">
              <router-link :to="{ name: 'auxiliar_installationTypes_edit_gei', params: { id: data.item.id} }">
                <feather-icon
                    :id="`invoice-row-${data.item.id}-send-icon`"
                    icon="EditIcon"
                    class="cursor-pointer"
                    size="16"
                />

              </router-link>

              <feather-icon
                  :id="`invoice-row-${data.item.id}-preview-icon`"
                  icon="TrashIcon"
                  size="16"
                  class="cursor-pointer ml-1 text-red"
                  @click="deleteInstallationTypes(data.item.id)"
              />
              <b-tooltip
                  title="Eliminar Instalación"
                  :target="`invoice-row-${data.item.id}-preview-icon`"
              />
            </template>
          </b-table>

          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">Mostrando {{ pageLength }} de {{ totalRecords }} tipos de instalaciones</span>
              </b-col>

              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <b-pagination
                    :value="1"
                    :total-rows="totalRecords"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="changePage"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {BRow, BCol, BTable, BCard, BButton, BTooltip, BPagination, BOverlay, BBadge} from 'bootstrap-vue'
import vSelect from "vue-select"
import {mapState} from "vuex"
import Swal from "sweetalert2"
import {GEI_AUXILIAR_INSTALLATIONTYPES_CREATE} from "@/router/web/constants"
import helpers from "@/resources/helpers/helpers"
import SearchView from "@/views/gei/auxiliars_installationTypes/list/components/searchView.vue"
import helper from "@/resources/helpers/helpers"

export default {
	name: "ListView",
  components: {
    BRow,
    BCol,
    BTable,
    BCard,
    BButton,
    BTooltip,
    BPagination,
    BOverlay,
    SearchView,
    vSelect,

  },
	data() {
		return {
			page: 1,
			pageLength: 10,
			totalRecords: 10,
      columns: [
        {key: 'type', label: 'Tipo de instalación', sortable: false},
        {key: 'code', label: 'Código', sortable: false},
        {key: 'actions', label: 'Acciones', sortable: false},
      ],
			showLoading: false,
            helpers
		}
	},
	computed: {
		...mapState('auxiliariesInstallationTypes', ['installation_types', 'search']),
	},
	created() {
		this.getInstallationTypes()
	},
	methods: {
		can(permission) {
			return this.$store.getters.can(permission)
		},
		formatDate(date) {
			const dateFormated = new Date(date)

			return dateFormated.toLocaleDateString("es-ES")
		},
    getInstallationTypes() {
			this.showLoading = true

      this.$store.dispatch('auxiliariesInstallationTypes/searchInstallationTypes', {
				per_page: this.pageLength,
				page: this.page,
        filter: this.search,
			})
				.then(response => {

					this.showLoading = false
          this.totalRecords = response.total_records

				})
				.catch(error => {
					this.showLoading = false
					console.log(error)
				})
		},
		deleteInstallationTypes(id) {
			Swal.fire({
                title: "Estás seguro que quieres eliminar este tipo de instalación?",
                text: "Una vez borrada no se podra recuperar!",
                icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#34c38f",
				cancelButtonColor: "#f46a6a",
				confirmButtonText: "Sí, borrar!",
				cancelButtonText: "Cancelar",
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('auxiliariesInstallationTypes/deleteInstallationTypes', {id: id})
						.then(response => {
							this.showLoading = false
                            helper.showToast('Tipo de instalación eliminado correctamente!', 'CheckIcon', 'success')
							this.getInstallationTypes()
						})
						.catch(error => {
							this.showLoading = false
                            helper.showToast('Error al eliminar el tipo de instalación', 'AlertOctagonIcon', 'danger')
                            console.log(error)
						})
				}
			})
		},
    changePage(page) {
      this.page = page
      this.getInstallationTypes()
    },
    changeLength(length) {
      this.length = length
      this.getInstallationTypes()
    },
    searchUser() {
			this.getInstallationTypes()
		},
		goToCreate(){
			this.$router.push(GEI_AUXILIAR_INSTALLATIONTYPES_CREATE)
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>